import superagent from 'superagent'
import prefix from 'superagent-prefix'
import use from 'superagent-use'
import bearer from 'superagent-auth-bearer'
import store from 'src/store'

import { getConfigSection } from '../config'

const config = getConfigSection('api')
const agent = use(bearer(superagent))

agent.use(prefix(config.base))

export function getAccessToken() {
    return store.getState().auth.user.access_token
}

export default agent