import produce from 'immer'

function routerModelFactory(history) {
    return {
        state: {
            location: null,
            action: null
        },

        reducers: {
            locationChanged: produce((draft, payload) => {
                const { location, action } = payload || {}

                draft.location = location
                draft.action = action
            })
        },

        effects: {
            push: action => history.push(action),
            replace: action => history.replace(action),
            go: action => history.go(action),
            goForward: () => history.goForward(),
            goBackward: () => history.goBackward()
        }
    }
}
export default routerModelFactory