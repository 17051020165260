import React from 'react'
import loadable from 'loadable-components'
import Icon from 'src/components/Icon'
import requireAuthentication from 'src/features/auth/requireAuthentication'
import TenantDetailBreadcrumb from './components/TenantDetailBreadcrumb'

export const subRouteLabels = {
    'details': 'Details',
    'providers': 'Providers'
}

export default [
    {
        path: '/tenants/create', 
        component: loadable(() => import('./TenantCreate')) 
    },
    { 
        path: '/tenants/:id', 
        component: requireAuthentication(loadable(() => import('./TenantDetail'))),
        breadcrumb: TenantDetailBreadcrumb
    },
    { 
        path: '/tenants', 
        label: 'Tenants', 
        component: requireAuthentication(loadable(() => import('./TenantIndex'))), 
        icon: <Icon icon="database" />
    }
]

export const subRoutes = [
    {
        path: '/tenants/:id/details',
        component: requireAuthentication(loadable(() => import('./components/TenantDetailForm'))),
        label: subRouteLabels.details
    },
    {
        path: '/tenants/:id/providers',
        component: requireAuthentication(loadable(() => import('./components/TenantProvidersDetail'))),
        label: subRouteLabels.providers
    }
]