import produce from 'immer'
import { findIndex } from 'lodash'
import effects from './effects'

export default {
    state: {
        list: {},
        selected: {}
    },

    reducers: {
        set: produce((draft, clients = []) => {
            draft.list = {}
            clients.forEach(client => {
                draft.list[client.clientId] = client
            })
        }),

        select: produce((draft, selected = {}) => {
            draft.selected = selected
        }),

        add: produce((draft, { key, item }) => {
            if (draft.selected[key]) {
                draft.selected[key].push(item)
            }
        }),

        edit: produce((draft, { key, item }) => {
            if (draft.selected[key]) {
                let index = findIndex(draft.selected[key], x => x.id === item.id)
                draft.selected[key][index] = item
            }
        }),

        delete: produce((draft, { key, id }) => {
            if (draft.selected[key]) {
                let index = findIndex(draft.selected[key], x => x.id === id)
                draft.selected[key].splice(index, 1)
            }
        })
    },

    selectors: {
        list(state) {
            return Object.values(state.clients.list)
        },

        selected(state) {
            return state.clients.selected
        },

        redirectUris(state) {
            return this.selected(state).redirectUris
        },

        postLogoutUris(state) {
            return this.selected(state).postLogoutUris
        },

        scopes(state) {
            return this.selected(state).scopes
        },

        secrets(state) {
            return this.selected(state).secrets
        }
    },

    effects
}
