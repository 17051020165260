function createConfigKey(prefix, key) {
    return `${prefix}_${key}`
}

export function createConfigSection(name, settings) {
    return Object.keys(settings).reduce((cfg, key) => ({
        ...cfg,
        [createConfigKey(name, key)]: settings[key]
    }), {})
}

export function getConfig(config, key) {
    return config[key]
}

export function getConfigSection(config, section) {
    return Object.keys(config)
        .filter(key => key.slice(0, section.length) === section)
        .reduce((map, key) => ({
            ...map,
            [key.substr(section.length + 1)]: getConfig(config, key)
        }), {})
}