import api, { getAccessToken } from 'src/services/api'

export default {
    post(clientId = '', uri = {}) { 
        return api
            .post(`/api/clients/${clientId}/postlogouturis`)
            .authBearer(getAccessToken())
            .send(uri)
            .type('application/json')
    },

    put(clientId = '', uri = {}) {
        return api
            .put(`/api/clients/${clientId}/postlogouturis/${uri.id}`)
            .authBearer(getAccessToken())
            .send(uri)
            .type('application/json')
    },

    delete(clientId = '', uriId = 0) {
        return api
            .del(`/api/clients/${clientId}/postlogouturis/${uriId}`)
            .authBearer(getAccessToken())
            .type('application/json')
    }
}