import { dispatch } from '@rematch/core'

import NotificationService from 'src/services/NotificationService'
import api from '../api'

export default {
    async addSecret({ clientId = '', secret = {} }) {
        try {
            const message = await dispatch(NotificationService.notifySaving({ message: `Adding "${secret.description}"...` }))
            const response = await api.secrets.post(clientId, secret)

            dispatch.clients.add({ key: 'secrets', item: response.body.value })

            dispatch(NotificationService.notifySaved(message, `Added "${secret.description}"`))
        } catch (error) {
            dispatch(NotificationService.notifyError(error, `An error occurred while adding secret ${secret.description} for client ${clientId}`))
        }
    },
    async deleteSecret({ clientId = '', id = 0 }) {
        try {
            const message = await dispatch(NotificationService.notifySaving({ message: `Deleting secret with id "${id}"...` }))
            const response = await api.secrets.delete(clientId, id)

            if (response.body) {
                dispatch.clients.delete({ key: 'secrets', id })
                dispatch(NotificationService.notifySaved(message, `Deleted secret with id "${id}"`))
            } else {
                dispatch(NotificationService.notifyWarning(message, `Something is not letting us delete secret with "${id}"`))
            }
        } catch (error) {
            dispatch(NotificationService.notifyError(error, `An error occurred while deleting secret with id ${id} for client ${clientId}`))
        }
    }
}