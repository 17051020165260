import React from 'react'
import Icon from '../components/Icon'
import DashboardIndex from './dashboard/DashboardIndex'

import clientsRoutes from './clients/routes'
import tenantsRoutes from './tenants/routes'
import usersRoutes from './users/routes'
import providersRoutes from './providers/routes'
import authRoutes from './auth/routes'

const routes = [
    { path: '/', exact: true, component: DashboardIndex, label: 'Home', icon: <Icon icon="home" />, breadcrumb: 'Home' },
    ...authRoutes,
    ...clientsRoutes,
    ...tenantsRoutes,
    ...providersRoutes,
    ...usersRoutes,
    { path: '/system', label: 'System', icon: <Icon icon="cog" />, component: null }
]

export default routes