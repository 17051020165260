import React from 'react'
import loadable from 'loadable-components'
import Icon from 'src/components/Icon'
import requireAuthentication from 'src/features/auth/requireAuthentication'

import ProviderDetailBreadcrumb from './components/ProviderDetailBreadcrumb'
import ProviderCreateBreadcrumb from './components/ProviderCreateBreadcrumb'

export const subRouteLabels = {
    'details': 'Details',
    'protocol': 'Protocol'
}

export default [
    {
        path: '/providers/create',
        component: requireAuthentication(loadable(() => import('./ProviderCreate'))), 
        breadcrumb: ProviderCreateBreadcrumb
    },
    { 
        path: '/providers/:id', 
        component: requireAuthentication(loadable(() => import('./ProviderDetail'))), 
        breadcrumb: ProviderDetailBreadcrumb 
    },
    {
        path: '/providers', 
        label: 'Providers', 
        component: requireAuthentication(loadable(() => import('./ProviderIndex'))), 
        icon: <Icon icon="id-card" /> 
    }    
]

export const subRoutes = [
    {
        path: '/providers/:id/details',
        component: requireAuthentication(loadable(() => import('./components/ProviderDetailForm'))),
        label: subRouteLabels.details
    },
    {
        path: '/providers/:id/protocol',
        component: requireAuthentication(loadable(() => import('./components/ProviderProtocolDetail'))),
        label: subRouteLabels.protocol
    }
]