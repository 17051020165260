import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getConfig } from '../../config'
import './LoginInfo.css'

const unAuthenticated = null

class LoginInfo extends React.PureComponent {
    static propTypes = {
        user: PropTypes.object
    }
    
    handleClick(e) {
        e.preventDefault()
        window.location = getConfig('oidc_authority') + '/account/logout'
    }

    render() {
        if (this.props.user === null) {
            return (
                <div className="ao-logininfo">
                    <Link to="/login">Login</Link>
                </div>
            )
        } else {
            return (
                <div className="ao-logininfo">
                    <a href={ getConfig('oidc_authority') + '/account/logout' } onClick={ this.handleClick }>Logout</a>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user ? state.auth.user.profile : unAuthenticated
    }
}

export default connect(mapStateToProps)(LoginInfo)