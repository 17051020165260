import { dispatch } from '@rematch/core'

import NotificationService from 'src/services/NotificationService'
import api from '../api'

export default {
    async search(filter = {}) {
        try {
            const response = await api.list(filter)
            dispatch.clients.set(response.body)
            
        } catch (error) { 
            dispatch(NotificationService.notifyError(error, 'An error occurred while searching clients'))
        }
    },
    async get(id = '') {
        try {
            const response = await api.get(id)
            dispatch.clients.select(response.body)
        } catch (error) {
            dispatch(NotificationService.notifyError(error, `An error occurred while getting client ${id}`))
        }
    },
    async update(client = {}) {
        try {
            const message = await dispatch(NotificationService.notifySaving({ message: `Saving "${client.name}"...` }))
            const response = await api.put(client.id, client)

            dispatch.clients.select(response.body)

            dispatch(NotificationService.notifySaved(message, `Saved "${client.name}"`))
        } catch (error) {
            dispatch(NotificationService.notifyError(error, `An error occurred while saving provider ${client.name}`))
        }   
    }
}