import api, { getAccessToken } from 'src/services/api'

export default {
    post(clientId = '', scope = {}) {
        return api
            .post(`/api/clients/${clientId}/scopes`)
            .authBearer(getAccessToken())
            .send(scope)
            .type('application/json')
    },

    put(clientId = '', scope = {}) {
        return api
            .put(`/api/clients/${clientId}/scopes/${scope.id}`)
            .authBearer(getAccessToken())
            .send(scope)
            .type('application/json')
    },

    delete(clientId = '', scopeId = 0) {
        return api
            .del(`/api/clients/${clientId}/scopes/${scopeId}`)
            .authBearer(getAccessToken())
            .type('application/json')
    }
}
