import api, { getAccessToken } from 'src/services/api'

const tenantApi = {
    list(filter = {}) {
        return api
            .get('/api/tenants')
            .authBearer(getAccessToken())
            .query(filter)
            .type('application/json')
    },

    get(tenantId = '') {
        return api
            .get(`/api/tenants/${tenantId}`)
            .authBearer(getAccessToken())
            .type('application/json')
    },

    put(tenantId = '', tenant = {}) { 
        return api
            .put(`/api/tenants/${tenantId}`)
            .authBearer(getAccessToken())
            .send(tenant)
            .type('application/json')
    },

    post(tenant = {}) {
        return api
            .post('/api/tenants')
            .authBearer(getAccessToken())
            .send(tenant)
            .type('application/json')
    },

    providers: {
        delete(tenantId = '', providerId = '') {
            return api
                .del(`/api/tenants/${tenantId}/providers/${providerId}`)
                .authBearer(getAccessToken())
        }
    }
} 

export default tenantApi