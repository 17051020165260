import React from 'react' 
import PropTypes from 'prop-types'
import cx from 'classnames'
import { connect } from 'react-redux'
import { 
    Nav, 
    NavItem 
} from 'reactstrap'

import Icon from '../Icon'
import NavigationItem from './NavigationItem'

import './Navigation.css'

class Navigation extends React.Component {
    
    static propTypes = {
        className: PropTypes.string,
        horizontal: PropTypes.bool,
        routes: PropTypes.array,
        vertical: PropTypes.bool,
        authenticated: PropTypes.bool
    }

    static defaultProps = {
        horizontal: true,
        vertical: false,
        routes: []
    }

    state = {
        expanded: false
    }

    toggle = () => {
        this.setState(state => ({
            expanded: !state.expanded
        }))
    }

    render() {
        const isHorizontal = this.props.horizontal && !this.props.vertical
        const classes = cx('ao-navigation', this.props.className, {
            'ao-navigation--horizontal': isHorizontal,
            'ao-navigation--vertical': !isHorizontal,
            'ao-navigation--expanded': this.state.expanded
        })
        const innerNavClasses = cx('ao-navigation', {
            'ao-navigation-inner-not-visible': !this.props.authenticated
        })

        return ( 
            <nav className={ classes }>
                <Nav className = { innerNavClasses }>
                    <NavItem className="ao-navigation__item ao-navigation__toggler">
                        <button type="button" className="navbar-toggler nav-link" onClick={ this.toggle }>
                            <Icon icon="bars" />
                        </button>
                    </NavItem>
                    { this.props.routes.map((route, i) => (
                        <NavigationItem key={ `navitem-${i}` } { ...route } />
                    )) }
                </Nav>
                <Nav className="mt-auto">
                    <NavItem className="ao-navigation__item" />
                </Nav>
            </nav>
        )
    }
}

function mapStateToProps(state) {
    return {
        authenticated: state.auth.user ? true : false
    }
}

export default connect(mapStateToProps)(Navigation)
