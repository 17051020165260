import { init } from '@rematch/core'
import configureSelectPlugin from '@rematch/select'
import configureRouterPlugin from './plugins/router'
import { loadUser, reducer as oidcReducer } from 'redux-oidc'
import { reducer as notificationsReducer } from 'reapop'
import thunk from 'redux-thunk'
import models from 'src/features/model'
import history from './history'

import userManager from 'src/services/UserManager'

const routerPlugin = configureRouterPlugin(history)
const selectPlugin = configureSelectPlugin({ sliceState: rootState => rootState }) 

const store = init({
    models,
    plugins: [ 
        routerPlugin,
        selectPlugin
    ],
    redux: {
        reducers: {
            auth: oidcReducer,
            notifications: notificationsReducer()
        },
        middlewares: [ thunk ]
    }
})

window.store = store

loadUser(store, userManager)

export default store