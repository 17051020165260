import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import { get } from 'lodash'
import Loading from 'src/components/Loading'

const emptyRoles = []

window.get = get

const requireAuthenticationDefaults = {
    authenticatedSelector: state => get(state, [ 'auth', 'user', 'profile', 'role' ], emptyRoles).indexOf('Administrator') > -1,
    authenticatingSelector: state => state.auth.isLoading,
    wrapperDisplayName: 'RequireAuthentication'
}
  
export const requireAuthentication = connectedAuthWrapper(requireAuthenticationDefaults)
export const requireAuthenticationRedirect = connectedRouterRedirect({
    ...requireAuthenticationDefaults,
    AuthenticatingComponent: Loading,
    redirectPath: '/login'
})

export default requireAuthentication