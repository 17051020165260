import React from 'react'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'react-router-breadcrumbs-hoc'
import { Link } from 'react-router-dom'
import {
    Breadcrumb as BSBreadcrumb, 
    BreadcrumbItem as BSBreadcrumbItem
} from 'reactstrap'

import './Breadcrumb.css'

import routes from '../../features/routes'

function Breadcrumb({ breadcrumbs }) {
    return (
        <div className="ao-breadcrumb">
            <BSBreadcrumb tag="nav">
                { 
                    breadcrumbs.map((breadcrumb, index) => (
                        breadcrumbs.length -1 > index 
                            ? <BSBreadcrumbItem key={ index } tag={ Link } to={ breadcrumb.props.match.url }>{ breadcrumb }</BSBreadcrumbItem>
                            : <BSBreadcrumbItem key={ index } tag="span" active>{ breadcrumb }</BSBreadcrumbItem>
                    ))
                }
            </BSBreadcrumb>
        </div>
    )
}

Breadcrumb.propTypes = {
    breadcrumbs: PropTypes.array
}
 
export default withBreadcrumbs(routes)(Breadcrumb)