import React from 'react'
import PropTypes from 'prop-types'
import { NavLink as RRNavLink } from 'react-router-dom'

import { 
    NavItem,
    NavLink
} from 'reactstrap'

function NavigationItem({ label, icon, path }) {
    if (!label && !icon) {
        return null
    }

    return (
        <NavItem className="ao-navigation__item">
            <NavLink tag={ RRNavLink } activeClassName="active" to={ path }>
                {icon}
                <span className="ao-navigation_itemlabel">{label}</span>
            </NavLink>
        </NavItem>
    )
}

NavigationItem.propTypes = {
    icon: PropTypes.oneOfType([ PropTypes.func, PropTypes.object ]),
    label: PropTypes.string,
    path: PropTypes.string
}

export default NavigationItem