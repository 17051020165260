import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { select } from '@rematch/select'

function ProviderDetailBreadcrumb({ name }) {
    return (
        <span>{ name }</span>
    )
}

ProviderDetailBreadcrumb.propTypes = {
    name: PropTypes.string
}

ProviderDetailBreadcrumb.defaultProps = {
    name: ''
}

function mapStateToProps(state) {
    return { name: select.providers.selected(state).name }
}

export default connect(mapStateToProps)(ProviderDetailBreadcrumb)