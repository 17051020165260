import clients from './clients'
import scopes from './scopes'
import redirectUris from './redirectUris'
import postLogoutUris from './postLogoutUris'
import secrets from './secrets'

export default { 
    ...clients,
    postLogoutUris,
    secrets,
    scopes,
    redirectUris
}