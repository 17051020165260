import client from './client'
import redirectUris from './redirectUris'
import postLogout from './postLogout'
import scopes from './scopes'
import secrets from './secrets'

export default {
    ...client,
    ...redirectUris,
    ...postLogout,
    ...scopes,
    ...secrets
}