import React from 'react'
import NotificationsSystem from 'reapop'

import theme from './theme'

export default class Notification extends React.Component {
    render() {
        return (
            <NotificationsSystem theme={ theme } />
        )
    }
}