import React from 'react'
import loadable from 'loadable-components'
import Icon from 'src/components/Icon'
import requireAuthentication from 'src/features/auth/requireAuthentication'

export const subRouteLabels = {
    'details': 'Details',
    'redirect': 'Redirect Uri\'s',
    'postlogout': 'Postlogout Uri\'s',
    'scopes': 'Scopes',
    'secrets': 'Secrets'
}

export default [ { 
    path: '/clients/:id', 
    component: requireAuthentication(loadable(() => import('./ClientDetail')))
}, { 
    path: '/clients', 
    exact: true,
    component: requireAuthentication(loadable(() => import('./ClientIndex'))),
    label: 'Clients', 
    icon: <Icon icon="desktop" /> 
} ]

export const subRoutes = [ {
    path: '/clients/:id/details',
    component: requireAuthentication(loadable(() => import('./components/ClientDetailForm'))),
    label: subRouteLabels.details
}, {
    path: '/clients/:id/redirect',
    component: requireAuthentication(loadable(() => import('./components/ClientRedirectUri'))),
    label: subRouteLabels.redirect
}, {
    path: '/clients/:id/postlogout',
    component: requireAuthentication(loadable(() => import('./components/ClientPostLogoutUri'))),
    label: subRouteLabels.postlogout
}, {
    path: '/clients/:id/scopes',
    component: requireAuthentication(loadable(() => import('./components/ClientScope'))),
    label: subRouteLabels.scopes
}, {
    path: '/clients/:id/secrets',
    component: requireAuthentication(loadable(() => import('./components/ClientSecret'))),
    label: subRouteLabels.secrets
} ]

