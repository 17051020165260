import { createConfigSection } from '../helpers'

export default {

    ...createConfigSection('api', {
        base: '',
        requestTimeout: 2000
    }),

    ...createConfigSection('oidc', {
        authority: '',
        loadUserInfo: true,
        client_id: 'idp.client',
        response_type: 'token id_token',
        scope: 'openid roles idp.api'
    })
}