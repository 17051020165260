import React from 'react'
import loadable from 'loadable-components'
import Icon from 'src/components/Icon'
import UserDetailBreadcrumb from './components/UserDetailBreadcrumb'
import requireAuthentication from 'src/features/auth/requireAuthentication'

export default [
    { 
        path: '/users/:id/impersonate', 
        requireAuthentication: true,
        component: requireAuthentication(loadable(() => import('./Impersonate')))
    }, { 
        path: '/users/:id', 
        requireAuthentication: true,
        component: requireAuthentication(loadable(() => import('./UserDetail'))), 
        breadcrumb: UserDetailBreadcrumb
    }, { 
        path: '/users', 
        requireAuthentication: true,
        component: requireAuthentication(loadable(() => import('./UserIndex'))),
        label: 'Users', 
        icon: <Icon icon="users" />
    }
]