import { dispatch } from '@rematch/core'

import NotificationService from 'src/services/NotificationService'
import api from '../api'

export default {
    async addScope({ clientId = '', scope = '' }) {
        try {
            const message = await dispatch(NotificationService.notifySaving({ message: `Adding "${scope}"...` }))
            const response = await api.scopes.post(clientId, { scope })

            dispatch.clients.add({ key: 'scopes', item: response.body.value })

            dispatch(NotificationService.notifySaved(message, `Added "${scope}"`))
        } catch (error) {
            dispatch(NotificationService.notifyError(error, `An error occurred while adding scope ${scope} for client ${clientId}`))
        }
    },
    async editScope({ clientId = '', scope = {} }) {
        try {
            const message = await dispatch(NotificationService.notifySaving({ message: `Saving "${scope.scope}"...` }))
            const response = await api.scopes.put(clientId, scope )

            dispatch.clients.edit({ key: 'scopes', item: response.body })

            dispatch(NotificationService.notifySaved(message, `Saved "${scope.scope}"`))
        } catch (error) {
            dispatch(NotificationService.notifyError(error, `An error occurred while saving scope ${scope.scope} for client ${clientId}`))
        }
    },
    async deleteScope({ clientId = '', id = 0 }) {
        try {
            const message = await dispatch(NotificationService.notifySaving({ message: `Deleting scope with id "${id}"...` }))
            const response = await api.scopes.delete(clientId, id)

            if (response.body) {
                dispatch.clients.delete({ key: 'scopes', id })
                dispatch(NotificationService.notifySaved(message, `Deleted scope with id "${id}"`))
            } else {
                dispatch(NotificationService.notifyWarning(message, `Something is not letting us delete scope with "${id}"`))
            }
        } catch (error) {
            dispatch(NotificationService.notifyError(error, `An error occurred while deleting scope with id ${id} for client ${clientId}`))
        }
    }
}